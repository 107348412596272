import type { SearchSuggestion } from '@/api/v4/search.api';
import { useEnrichedCompanySuggestions } from './use-enriched-company-suggestions.query';
import { useMemo } from 'react';
import type { EnrichedCompanySearchSuggestion } from '@/api/v4/organization-enrich.api';
import { useCompanySuggestionsTableConfig } from './use-company-suggestions-table-config';
import { TableHeaderCell } from '@/ui/table/infinite-table/table-header-cell';
import { styled } from 'goober';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { colors } from '@/theme/colors';
import { combineCompanyData } from './combine-company-data';

export type CompanySearchSuggestion = {
  basicData: SearchSuggestion;
  enrichedData: EnrichedCompanySearchSuggestion;
};

export const CompanySuggestionsTable = ({
  data,
}: {
  data: SearchSuggestion[];
}) => {
  const searchSuggestionsCompanyIds = data.map(suggestion => suggestion.id);
  const { data: enrichedCompanySuggestions, isLoading } =
    useEnrichedCompanySuggestions(searchSuggestionsCompanyIds);

  const combinedCompaniesData = useMemo(
    () => combineCompanyData(data, enrichedCompanySuggestions),
    [data, enrichedCompanySuggestions],
  );

  const { rows, headers } = useCompanySuggestionsTableConfig(
    combinedCompaniesData,
    isLoading,
  );

  if (!isLoading && !combinedCompaniesData.length) {
    return null;
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            {headers.cells.map(cell => (
              <TableHeaderCell key={cell.columnKey} cell={cell} />
            ))}
          </tr>
        </thead>
        <TableBody
          id="company-enriched-suggestions-table-body"
          rows={rows}
          isLoading={isLoading}
          borderColor={colors.gray.c3}
        />
      </Table>
    </div>
  );
};

const Table = styled('table')`
  ${({ theme }) => theme.mixins.scrollbar};
  border-collapse: separate;
  overflow: auto;
  display: inherit;
  max-height: 500px;
  border-radius: 12px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  min-width: 100%;
`;
